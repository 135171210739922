import type { NextPage } from 'next'
import Head from 'next/head'
import Image from 'next/image'
import styles from '../styles/Home.module.css'

const Home: NextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>Discotalk - your creative AI toolkit.</title>
        <meta name="description" content="Create beautiful images from your phone with the latest AI models in Discotalk." />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={styles.main}>
        <div id="wrapper">
          <div id="main">
            <div className="inner">
              <div id="container01" className="style3 container default">
                <div className="wrapper">
                  <div className="inner" data-onvisible-trigger="1">
                    <div id="image04" className="style1 image">
                      <span className="frame"><img src="assets/images/icon.png" alt="" /></span>
                    </div>
                    <h1 id="text01" className="style2">Discotalk - The AI creativity app</h1>
                    <p id="text16" className="style4">Create beautiful images from your phone with the latest AI models in Discotalk.</p>
                    <ul id="icons02" className="style1 icons">
                      <li>
                        <a className="n01" href="#start">
                          <svg><use xlinkHref="assets/icons.svg#arrow-down-alt-light"></use></svg>
                          <span className="label">Chevron Down (Light)</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr id="divider02" className="style1" />
              <div id="container03" data-scroll-id="start" data-scroll-behavior="center" data-scroll-offset="0" data-scroll-invisible="1" className="style2 container columns">
                <div className="wrapper">
                  <div className="inner" data-onvisible-trigger="1" data-reorder="1,0">
                    <div>
                      <p id="text02" className="style3">AI fueled creativity</p>
                      <h2 id="text03" className="style1">You imagine -<br/> AI draws</h2>
                      <p id="text05" className="style4">Work together with the Discotalk AI to create incredible images in any artistic style. If you can imagine it, AI can draw it.</p>
                    </div>
                    <div>
                      <div id="image01" className="style3 image" data-position="center">
                        <span className="frame"><img src="assets/images/image01.jpg" alt="" /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr id="divider01" className="style1" />
              <div id="container04" className="style2 container columns">
                <div className="wrapper">
                  <div className="inner" data-onvisible-trigger="1" data-reorder="0,1">
                    <div>
                      <div id="image02" className="style3 image" data-position="center">
                        <span className="frame"><img src="assets/images/image02.jpg" alt="" /></span>
                      </div>
                    </div>
                    <div>
                      <p id="text06" className="style3">Discotalk Community</p>
                      <h2 id="text07" className="style1">Learn with the AI creator community</h2>
                      <p id="text08" className="style4">Share your creations with the fast-growing Discotalk creator community, evolve other artworks and earn credits for more creations.</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr id="divider03" className="style1" />
              <div id="container02" className="style3 container default">
                <div className="wrapper">
                  <div className="inner" data-onvisible-trigger="1">
                    <p id="text12" className="style3">App Store</p>
                    <h2 id="text10" className="style1">Discotalk for iOS</h2>
                    <p id="text11" className="style4">Join the Discotalk community of creators and start experimenting with the latest AI techniques.</p>
                    <div id="image03" className="style3 image" data-position="center">
                      <a href="https://apps.apple.com/us/app/discotalk-ai-creativity-app/id1644552669" className=""><img src="/assets/images/download_appstore.png" alt="" /></a>
                    </div>
                    <ul id="buttons01" className="style1 buttons">
                      <li>
                        <a href="https://apps.apple.com/th/app/discotalk-ai-creativity-app/id1644552669" className="button n01"><svg><use xlinkHref="assets/icons.svg#arrow-right-alt-light"></use></svg><span className="label">Download</span></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr id="divider04" className="style1" />
              <div id="container05" className="style3 container default">
                <div className="wrapper">
                  <div className="inner" data-onvisible-trigger="1">
                    <p id="text09" className="style3">Discotalk Socials</p>
                    <h2 id="text04" className="style1">Follow Discotalk</h2>
                    <p id="text20" className="style4">Be notified of new features and follow trending creations by the Discotalk community.</p>
                    <ul id="icons01" className="style2 icons">
                      <li>
                        <a className="n01" href="https://twitter.com/discotalkai">
                          <svg><use xlinkHref="assets/icons.svg#twitter"></use></svg>
                          <span className="label">Twitter</span>
                        </a>
                      </li>
                      <li>
                        <a className="n01" href="https://discord.gg/MrMSdnQdNG">
                          <svg><use xlinkHref="assets/icons.svg#discord"></use></svg>
                          <span className="label">Discord</span>
                        </a>
                      </li>
                      <li>
                        <a className="n02" href="https://facebook.com/discotalkai">
                          <svg><use xlinkHref="assets/icons.svg#facebook"></use></svg>
                          <span className="label">Facebook</span>
                        </a>
                      </li><li>
                        <a className="n03" href="https://instagram.com/discotalkai">
                          <svg><use xlinkHref="assets/icons.svg#instagram"></use></svg>
                          <span className="label">Instagram</span>
                        </a>
                      </li><li>
                        <a className="n04" href="mailto:support@discotalk.app">
                          <svg><use xlinkHref="assets/icons.svg#email"></use></svg>
                          <span className="label">Email</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default Home
